<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-2, .cls-3, .cls-4, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#inox-gradient-5);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 112.96" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 103.08" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 197.7" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 187.8" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 171.59" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 129.18" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 167.34" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 133.42" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 86.77" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 44.36" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 82.53" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 48.6" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 256.4" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 213.99" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 252.16" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 218.24" 
      xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="S09">
    <polyline id="inox" v-if="showInox"
              data-name="inox" 
              class="cls-3" 
              :points="`${doorLeftWidth1 + 75.75} ${doorTopHeight1 + 103.08} ${doorLeftWidth1 + 65.85} ${doorTopHeight1 + 103.08} ${doorLeftWidth1 + 65.85} ${doorTopHeight1 + 112.97} ${doorLeftWidth1 + 75.75} ${doorTopHeight1 + 112.97} ${doorLeftWidth1 + 75.75} ${doorTopHeight1 + 103.08}`"/>
    <polyline id="inox-2" v-if="showInox"
              data-name="inox" 
              class="cls-4" 
              :points="`${doorLeftWidth1 + 65.85} ${doorTopHeight1 + 197.7} ${doorLeftWidth1 + 75.75} ${doorTopHeight1 + 197.7} ${doorLeftWidth1 + 75.75} ${doorTopHeight1 + 187.8} ${doorLeftWidth1 + 65.85} ${doorTopHeight1 + 187.8} ${doorLeftWidth1 + 65.85} ${doorTopHeight1 + 197.7}`"/>
    <polyline id="inox-3" v-if="showInox"
              data-name="inox" 
              class="cls-6" 
              :points="`${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 129.18} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 129.18} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 171.59} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 171.59} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 129.18}`"/>
    <polyline filter="url(#inset-shadow)" id="glass" 
              class="cls-7" 
              :points="`${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 133.42} ${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 133.42} ${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 167.34} ${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 167.34} ${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 133.42}`"/>
    <polyline id="inox-4" v-if="showInox"
              data-name="inox" 
              class="cls-8" 
              :points="`${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 44.36} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 44.36} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 86.77} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 86.77} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 44.36}`"/>
    <polyline filter="url(#inset-shadow)" id="glass-2" 
              data-name="glass" 
              class="cls-9" 
              :points="`${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 48.6} ${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 48.6} ${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 82.53} ${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 82.53} ${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 48.6}`"/>
    <polyline id="inox-5" v-if="showInox"
              data-name="inox" 
              class="cls-10" 
              :points="`${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 256.4} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 256.4} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 213.99} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 213.99} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 256.4}`"/>
    <polyline filter="url(#inset-shadow)" id="glass-3" 
              data-name="glass" 
              class="cls-11" 
              :points="`${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 252.16} ${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 252.16} ${doorLeftWidth1 + 87.77} ${doorTopHeight1 + 218.24} ${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 218.24} ${doorLeftWidth1 + 53.84} ${doorTopHeight1 + 252.16}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
